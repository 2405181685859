@import "definitions";

$tabs_bg_color: #fff;                                       // Reiterobjekt Hintergrundfarbe
$tab_head_spacing: .5em;                                    // Abstand zwischen Tabs
$tabs_head_bg: $theme_color;                                // Reier inaktiv - Hintergrund
$tabs_head_font_color: $theme_font_color;                   // Reiter inaktiv - Schriftfarbe
$tab_head_active_font_color: $base_font_color;              // aktiver Reiter Schriftfarbe
$tab_head_active_bg: #fff;                                  // aktiver Reiter Hintergrundfarbe
$tab_head_active_border_top: 2px solid $highlight_color;    // aktiver Reiter Linie oben
$tab_head_active_border_bottom: 2px solid #fff;             // aktiver Reiter Linie unten
$tab_head_active_mar_bottom: -2px;                          // Ausbesserung damit beim aktiven Reiter Trennlinie unten nicht mehr sichtbar ist

$small_tab_head_spacing: .2em;                                    // Abstand zwischen Tabs


div.tabs{
    color: $base_font_color;
    margin-bottom: $mar;
    div.tabs_head {
    margin: 0;
        > * {
          position: relative;
          border: none;
          background-color: $tabs_head_bg;
          color: $tabs_head_font_color;
          padding: $pad_small $pad;
          margin-right: $tab_head_spacing;
          margin-bottom: $mar_small;
          text-transform: none;
          display: inline-block;
          cursor: pointer;
          border: 1px solid transparent;
          box-shadow: $main_box_shadow;
            &.active, &:hover:not(.disabled){
                color: $tab_head_active_font_color;
                background-color: $tab_head_active_bg;       
                border-top: $border;
                border-left: $border;
                box-shadow: $main_box_shadow;
            }
            &.active{
            /*background-color: $tab_head_active_bg;
            border: $border;
            border-top: $tab_head_active_border_top;
            border-bottom: $tab_head_active_border_bottom;
            margin-bottom: $tab_head_active_mar_bottom;  */
            }
            &.disabled {
                cursor: not-allowed;
                opacity: 0.5;            
            }
        }   
    }
    div.tabs_content{
        margin-top: $mar_small;
        padding: $pad;
        border-top: $border;
        border-left: $border;
        background: $tabs_bg_color;
        box-shadow: $main_box_shadow;
        .main_bg{
            box-shadow: none;        
        }
    }

    div.tabs_content .main_bg{
        border-bottom: $border;
        border-right: $border;
        //Causes problems with tooltips overflow: hidden;
    }
}

div.small_tabs{
    color: $base_font_color;
    margin-bottom: $mar;

    div.small_tabs_head {
        margin: 0;
        display: flex;

        >div {
            justify-content: flex-end;
            display:flex;
            flex-direction: column;
        }

        > div > * {
          position: relative;
          border: none;
          background-color: $tabs_head_bg;
          color: $tabs_head_font_color;
          padding: $pad_tiny $pad_small;
          margin-right: $small_tab_head_spacing;
          text-transform: none;
          display: inline-block;
          cursor: pointer;
          border: 1px solid transparent;  
        }   

        > div.active > * , > div:hover > * {
            color: $tab_head_active_font_color;
            background-color: $tab_head_active_bg;       
            border: $border;
            border-bottom: none;
        }
        > div.active > * {
            padding: calc(#{$pad_tiny} + 2px) $pad_medium;
            bottom: -1px;
        }
    }
    div.small_tabs_content{
        padding: $pad;
        border-top: $border;
        border-left: $border;
        background: $tabs_bg_color;
        box-shadow: $main_box_shadow;
        .main_bg{
            box-shadow: none;        
        }
    }

    div.tabs_content .main_bg{
        border-bottom: $border;
        border-right: $border;
        //Causes problems with tooltips overflow: hidden;
    }
}

div.entries_action_subtabs{
    margin-top: $mar;
}

.newsletter.tabs .tabs_head, .countries.tabs .tabs_head{
    margin-top: $mar_big;
    padding-left: $pad;
    padding-bottom: $pad_small;
    border-bottom: $border;
}

.countries.tabs .regions_editor {
    margin: $mar;
}

.rights {
    margin: $mar;

    .tabs {
        .tabs_head {
            margin-top: $mar;
            padding-bottom: $pad_small;
        }
    }
}